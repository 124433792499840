import { render, staticRenderFns } from "./PaymentsModeSetting.vue?vue&type=template&id=5054a0e7&"
import script from "./PaymentsModeSetting.vue?vue&type=script&lang=js&"
export * from "./PaymentsModeSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data01/virt82534/domeenid/www.kammo.ee/kaimar/invoice/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5054a0e7')) {
      api.createRecord('5054a0e7', component.options)
    } else {
      api.reload('5054a0e7', component.options)
    }
    module.hot.accept("./PaymentsModeSetting.vue?vue&type=template&id=5054a0e7&", function () {
      api.rerender('5054a0e7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/views/settings/PaymentsModeSetting.vue"
export default component.exports