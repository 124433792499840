var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.driver"),
                error: _vm.driverError,
                required: ""
              }
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_driver.$error,
                  options: _vm.mailDrivers,
                  searchable: true,
                  "allow-empty": false,
                  "show-labels": false
                },
                on: { input: _vm.onChangeDriver },
                model: {
                  value: _vm.mailConfigData.mail_driver,
                  callback: function($$v) {
                    _vm.$set(_vm.mailConfigData, "mail_driver", $$v)
                  },
                  expression: "mailConfigData.mail_driver"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.host"),
                error: _vm.hostError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_host.$error,
                  type: "text",
                  name: "mail_host"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_host.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_host,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_host",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_host"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("settings.mail.username") } },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: { type: "text", name: "db_name" },
                model: {
                  value: _vm.mailConfigData.mail_username,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_username"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            { attrs: { label: _vm.$t("settings.mail.password") } },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  type: _vm.getInputType,
                  name: "password",
                  autocomplete: "off",
                  "data-lpignore": "true"
                },
                scopedSlots: _vm._u([
                  {
                    key: "rightIcon",
                    fn: function() {
                      return [
                        _vm.isShowPassword
                          ? _c("eye-off-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                              on: {
                                click: function($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                }
                              }
                            })
                          : _c("eye-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                              on: {
                                click: function($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                }
                              }
                            })
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.mailConfigData.mail_password,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_password"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.port"),
                error: _vm.portError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_port.$error,
                  type: "text",
                  name: "mail_port"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_port.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_port,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_port",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_port"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.encryption"),
                error: _vm.encryptionError,
                required: ""
              }
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_encryption.$error,
                  options: _vm.encryptions,
                  searchable: true,
                  "show-labels": false
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.mail_encryption.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.mail_encryption,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_encryption",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_encryption"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.from_mail"),
                error: _vm.fromEmailError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_mail.$error,
                  type: "text",
                  name: "from_mail",
                  autocomplete: "off",
                  "data-lpignore": "true"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.from_mail.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.from_mail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_mail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_mail"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.from_name"),
                error: _vm.fromNameError,
                required: ""
              }
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_name.$error,
                  type: "text",
                  name: "from_name"
                },
                on: {
                  input: function($event) {
                    return _vm.$v.mailConfigData.from_name.$touch()
                  }
                },
                model: {
                  value: _vm.mailConfigData.from_name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_name"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex my-10" },
        [
          _c(
            "sw-button",
            {
              attrs: {
                disabled: _vm.loading,
                loading: _vm.loading,
                type: "submit",
                variant: "primary"
              }
            },
            [
              _c("save-icon", { staticClass: "mr-2" }),
              _vm._v("\n      " + _vm._s(_vm.$t("general.save")) + "\n    ")
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }