var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "relative h-full",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.updateCompanyData($event)
        }
      }
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.company_info.company_info")) +
                  "\n      "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("settings.company_info.section_description")
                    ) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid mb-6 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_logo")
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex items-center justify-center h-24 p-5 mt-2 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                      attrs: { id: "logo-box" }
                    },
                    [
                      _vm.previewLogo
                        ? _c("img", {
                            staticClass: "absolute opacity-100 preview-logo",
                            staticStyle: {
                              "max-height": "80%",
                              animation: "fadeIn 2s ease"
                            },
                            attrs: { src: _vm.previewLogo }
                          })
                        : _c(
                            "div",
                            { staticClass: "flex flex-col items-center" },
                            [
                              _c("cloud-upload-icon", {
                                staticClass:
                                  "h-5 mb-2 text-xl leading-6 text-gray-400"
                              }),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "text-xs leading-4 text-center text-gray-400"
                                },
                                [
                                  _vm._v(
                                    "\n              Drag a file here or\n              "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "cursor-pointer text-primary-500",
                                      attrs: { id: "pick-avatar" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                browse\n              "
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    "\n              to choose a file\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                    ]
                  ),
                  _vm._v(" "),
                  _c("sw-avatar", {
                    attrs: {
                      "preview-avatar": _vm.previewLogo,
                      trigger: "#logo-box"
                    },
                    on: {
                      changed: _vm.onChange,
                      uploadHandler: _vm.onUploadHandler,
                      handleUploadError: _vm.onHandleUploadError
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "icon",
                        fn: function() {
                          return [
                            _c("cloud-upload-icon", {
                              staticClass:
                                "h-5 mb-2 text-xl leading-6 text-gray-400"
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.company_name"),
                    error: _vm.nameError,
                    required: ""
                  }
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      invalid: _vm.$v.formData.name.$error,
                      placeholder: _vm.$t("settings.company_info.company_name")
                    },
                    on: {
                      input: function($event) {
                        return _vm.$v.formData.name.$touch()
                      }
                    },
                    model: {
                      value: _vm.formData.name,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "name", $$v)
                      },
                      expression: "formData.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.company_info.phone") } },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$t("settings.company_info.phone")
                    },
                    model: {
                      value: _vm.formData.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "phone", $$v)
                      },
                      expression: "formData.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.company_info.country"),
                    error: _vm.countryError,
                    required: ""
                  }
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.country_id.$error },
                    attrs: {
                      options: _vm.countries,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$t("general.select_country"),
                      label: "name",
                      "track-by": "id"
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.company_info.state") } },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$tc("settings.company_info.state"),
                      name: "state",
                      type: "text"
                    },
                    model: {
                      value: _vm.formData.state,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "state", $$v)
                      },
                      expression: "formData.state"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.company_info.city") } },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$tc("settings.company_info.city"),
                      name: "city",
                      type: "text"
                    },
                    model: {
                      value: _vm.formData.city,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "city", $$v)
                      },
                      expression: "formData.city"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                { attrs: { label: _vm.$tc("settings.company_info.zip") } },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      placeholder: _vm.$tc("settings.company_info.zip")
                    },
                    model: {
                      value: _vm.formData.zip,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "zip", $$v)
                      },
                      expression: "formData.zip"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$tc("settings.company_info.address"),
                        error: _vm.address1Error
                      }
                    },
                    [
                      _c("sw-textarea", {
                        class: {
                          invalid: _vm.$v.formData.address_street_1.$error
                        },
                        attrs: {
                          placeholder: _vm.$tc("general.street_1"),
                          rows: "2"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.address_street_1.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.address_street_1,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "address_street_1", $$v)
                          },
                          expression: "formData.address_street_1"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "my-2",
                      attrs: { error: _vm.address2Error }
                    },
                    [
                      _c("sw-textarea", {
                        class: {
                          invalid: _vm.$v.formData.address_street_2.$error
                        },
                        attrs: {
                          placeholder: _vm.$tc("general.street_2"),
                          rows: "2"
                        },
                        on: {
                          input: function($event) {
                            return _vm.$v.formData.address_street_2.$touch()
                          }
                        },
                        model: {
                          value: _vm.formData.address_street_2,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "address_street_2", $$v)
                          },
                          expression: "formData.address_street_2"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary"
              }
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tc("settings.company_info.save")) +
                  "\n    "
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }