var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.updatePaymentSetting($event)
            }
          }
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.payments.payment_prefix"),
                error: _vm.paymentPrefixError
              }
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.payments.payment_prefix.$error },
                on: {
                  input: function($event) {
                    return _vm.$v.payments.payment_prefix.$touch()
                  },
                  keyup: function($event) {
                    return _vm.changeToUppercase("PAYMENTS")
                  }
                },
                model: {
                  value: _vm.payments.payment_prefix,
                  callback: function($$v) {
                    _vm.$set(_vm.payments, "payment_prefix", $$v)
                  },
                  expression: "payments.payment_prefix"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.payment_number_length"
                ),
                error: _vm.paymentnumberLengthError
              }
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "60px" },
                attrs: {
                  invalid: _vm.$v.payments.payment_number_length.$error,
                  type: "number"
                },
                model: {
                  value: _vm.payments.payment_number_length,
                  callback: function($$v) {
                    _vm.$set(_vm.payments, "payment_number_length", $$v)
                  },
                  expression: "payments.payment_number_length"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.default_payment_email_body"
                )
              }
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.mailFields },
                model: {
                  value: _vm.payments.payment_mail_body,
                  callback: function($$v) {
                    _vm.$set(_vm.payments, "payment_mail_body", $$v)
                  },
                  expression: "payments.payment_mail_body"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.company_address_format"
                )
              }
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.payments.company_address_format,
                  callback: function($$v) {
                    _vm.$set(_vm.payments, "company_address_format", $$v)
                  },
                  expression: "payments.company_address_format"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.payments.from_customer_address_format"
                )
              }
            },
            [
              _c("base-custom-input", {
                attrs: { fields: _vm.customerAddressFields },
                model: {
                  value: _vm.payments.from_customer_address_format,
                  callback: function($$v) {
                    _vm.$set(_vm.payments, "from_customer_address_format", $$v)
                  },
                  expression: "payments.from_customer_address_format"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "my-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
                type: "submit"
              }
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.save")) +
                  "\n    "
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-3 mb-4" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setPaymentSetting },
              model: {
                value: _vm.paymentAutogenerate,
                callback: function($$v) {
                  _vm.paymentAutogenerate = $$v
                },
                expression: "paymentAutogenerate"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.autogenerate_payment_number"
                    )
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.payment_setting_description"
                    )
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mb-2" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setPaymentSetting },
              model: {
                value: _vm.paymentAsAttachment,
                callback: function($$v) {
                  _vm.paymentAsAttachment = $$v
                },
                expression: "paymentAsAttachment"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.payment_email_attachment"
                    )
                  ) +
                  "\n      "
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" }
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.payments.payment_email_attachment_setting_description"
                    )
                  ) +
                  "\n      "
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }