var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-swatches", {
        model: {
          value: _vm.color,
          callback: function($$v) {
            _vm.color = $$v
          },
          expression: "color"
        }
      }),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }