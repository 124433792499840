<template>
    <div>
        <v-swatches v-model="color"></v-swatches>

        <sw-divider class="mt-6 mb-8" />
    </div>
</template>
<script>
    import { mapActions, mapGetters } from 'vuex'
    import VSwatches from 'vue-swatches'

    export default {

        components: { VSwatches },
        data() {
            return {
                color: '#1CA085',
                isLoading: false,
            }
        },
    }
</script>
